/**
 * beet theme custom JS main file
 */
jQuery(document).ready(function () {

  /**
   * Mobile Search
   */

  $("#mobile_search_trigger").click(function () {
    // Toggle slide for the search bar
    $("#mobileSearch").slideToggle("fast");

    // Slide up the notice bar if it exists
    if ($("#noticeBar").length) {
      $("#noticeBar").slideToggle("fast");
    }
  });

  /**
   * Mobile Menu Trigger & Function
   */
  $(document).ready(function() {
    const burgerMenubtn = $("#burger");
    const mobileNav = $(".mobile-slide-menu");
    const closeButton = $(".close");
    const submenuLinks = $(".menu-item-has-children > a");
  
    // Toggle mobile menu
    burgerMenubtn.click(function () {
      mobileNav.toggleClass("mobile_nav_active");
    });
  
    // Close mobile menu
    closeButton.click(function () {
      mobileNav.removeClass("mobile_nav_active");
    });
  
    // Toggle submenus
    submenuLinks.click(function(e) {
      e.preventDefault(); // Prevent the default behavior of the link
      const subMenu = $(this).next('.sub-menu');
      
      // Toggle the submenu visibility
      if (subMenu.is(':visible')) {
        subMenu.slideUp();
      } else {
        $('.sub-menu').slideUp(); // Close any open submenus
        subMenu.slideDown(); // Open the clicked submenu
      }
    });
  
    // Close submenu when clicking outside
    $(document).click(function(e) {
      if (!$(e.target).closest('.menu-item-has-children').length) {
        $('.sub-menu').slideUp(); // Close all submenus
      }
    });
  });
  



  $(document).ready(function () {
    if($('.staff').length){
      $('.p_content').each(function() {
        var pContent = $(this);
        var fullContent = pContent.html();
        var truncatedContent = fullContent.substring(0, 180) + '... <span class="read-more">Read more</span>';

        if (fullContent.length > 180) {
            pContent.html(truncatedContent);
        }

        pContent.on('click', '.read-more', function() {
            pContent.html(fullContent + ' <span class="read-less">Read less</span>');
        });

        pContent.on('click', '.read-less', function() {
            pContent.html(truncatedContent);
        });
    });
    }
  });

  //copy to clip
  $("#copylink").click(function (e) {
    e.preventDefault;
    var dummy = document.createElement("input"),
      text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    alert("Post URL copied to clipboard.");
  });

});

/*header*/
jQuery(document).ready(function ($) {
  let header = $(".scrolled-nav");
  let lastScrolltop = 0;

  hasScrolled();
  setTimeout(hasScrolled, 800);
  $(window).on("scroll", hasScrolled);

  function hasScrolled() {
    let top = $(window).scrollTop();
    if (top > 500 && top <= lastScrolltop) {
      //setTimeout(addFixed,400);
      //header.addClass('fixed-top');
      header.addClass("fixed-top");
    }

    if (top < 500) {
      header.removeClass("fixed-top");
    }

    if ((header.hasClass("fixed-top") && top > lastScrolltop) || top < 400) {
      //setTimeout(removeFixed, 500);
      header.removeClass("fixed-top");
    }
    lastScrolltop = $(window).scrollTop();
  }

  function addFixed() {
    header.addClass("fixed-top");
  }

  function removeFixed() {
    header.removeClass("fixed-top");
  }
});

//accordion
if ($(".faqs").length) {
  if ($(".accordion").length) {
    var allPanels = $(".accordion dd").hide();

    $(".accordion dt > a").click(function () {
      var accordionItem = $(this).parents(".accordion_item");

      if (accordionItem.hasClass("active")) {
        accordionItem.removeClass("active");
      } else {
        accordionItem.addClass("active");
      }

      if ($(this).hasClass("active")) {
        $(this).parent().next().slideUp();
        $(this).removeClass("active");
      } else {
        allPanels.slideUp();
        $(".accordion dt > a").removeClass("active");
        $(this).addClass("active");
        $(this).parent().next().slideDown();
        return false;
      }
    });
  }
}