/**
 * Google Recaptcha
 */

$(document).ready(function() {

    /**
     * Honeypot approach
     */
    $('form').each(function() {
        // Honeypot Field
        $(this).append('<input type="text" name="honeypot_field" style="display:none" tabindex="-1" autocomplete="off">');

        // Timestamp Field
        var formTimestamp = Date.now();
        $(this).append('<input type="hidden" name="form_timestamp" value="' + formTimestamp + '">');

        // JavaScript Check Field (without using id)
        $(this).append('<input type="hidden" name="js_check_field" value="js_enabled">');
    
    });

    
    var recaptchaLoaded = false;

    function loadReCaptcha(siteKey) {
        if (recaptchaLoaded) return;
        recaptchaLoaded = true;

        var script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=' + siteKey;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        console.log('ReCaptcha Loaded');

        script.onload = function() {
            grecaptcha.ready(function() {
                $('form').each(function() {
                    grecaptcha.execute(siteKey, {action: 'submit'}).then(function(token) {
                        // Append or update the token in the form
                        var recaptchaResponse = $(this).find('input[name="recaptcha_response"]');
                        if (recaptchaResponse.length === 0) {
                            recaptchaResponse = $('<input>').attr({
                                type: 'hidden',
                                name: 'recaptcha_response'
                            }).appendTo(this);
                        }
                        recaptchaResponse.val(token);
                    }.bind(this));
                });
            });
        };
    }

    $('form').one('focus', 'input, select, textarea', function() {
        if (!recaptchaLoaded) {
            $.ajax({
                url: "/wp-admin/admin-ajax.php",
                type: 'POST',
                data: {
                    'action': 'retrieve_site_key_recaptcha'
                },
                dataType: 'json',
                success: function(response) {
                    const siteKey = response.siteKey;
                    loadReCaptcha(siteKey);
                },
                error: function(xhr, status, error) {
                    console.error("Error fetching site key: ", error);
                }
            });
        }
    });

    
});
